export default {
    integrations: 'Integrations',

    singleSignOns: 'Single Sign Ons',
    videoCameraSystems: 'Video Camera Systems',

    oktaTitle: 'Okta',
    oktaDescription: 'Import users from Okta',
    oktaSubDescription: 'You will need admin account in Okta',

    gsuiteTitle: 'GSuite',
    gsuiteDescription: 'Import users from GSuite',
    gsuiteSubDescription: 'You will need admin account in GSuite',

    azureTitle: 'Azure Active Directory',
    azureDescription: 'Import users from Azure Active Directory',
    azureSubDescription: 'You will need admin account in Azure Active Directory',

    eagleEyeTitle: 'Eagle Eye',
    eagleEyeDescription: 'Integrate Eagle Eye to view video clips for access events',
    eagleEyeSubDescription: 'Map Eagle Eye cameras to Swiftlane doors',
    eagleEyeImageAltText: 'eagle-eye',

    buttonConfigure: 'Configure',
    buttonUninstall: 'Uninstall',
    buttonInstall: 'Install',
    deleteConfirm: 'Are you sure want to delete this?',
    subscriptionRequired: 'Integration Subscription required for',
    requestSubscription: 'Request subscription',

    propertyManagementSystem: 'Property Management System',
    connections: 'Connections',
    locks: 'Smart Locks',
    accessControlSystems: 'Access Control Systems',
    logs: 'Logs',
    fileLogs: 'File Logs',
    syncLogs: 'Sync Logs',

    singleSignIn: {
        authorization: 'Authorization',
        authOktaTxt: 'Authenticate in Okta to proceed',
        authAzureTxt: 'Authenticate in Azure Active Directory to proceed',
        authGSuiteTxt: 'Authenticate in GSuite to proceed',
        enterOktaDomain: 'Enter your Okta domain',
        enterAzureDomain: 'Enter your domain or tenant id (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)',
        enterGSuiteDomain: 'Enter your GSuite domain',
        enterDomainName: 'Enter Domain name',
        clientID: 'Client ID',
        clientSecret: 'Client Secret',
        authenticate: 'Authenticate',
        synchronization: 'Synchronization',
        integrationDetails: 'Integration Details',
        connectionId: 'Connection ID',
        connectionType: 'Connection Type',
        connectionStatus: 'Connection Status',
        authorizerId: 'Authorizer ID',
        autoSync: 'Auto Sync',
        autoSyncTxt: 'All users and access groups will be auto-sync’ed every 2 hours',
        autoInvite: 'Auto Invite',
        autoInviteTxt: 'Send auto-invite when a new user is added curing auto-sync',
        addGroups: 'Add Groups',
        syncUsersManually: 'Sync Users Manually',
        reAuth: 'Re-authorize',
        groupRemoveConfirm:
            'Removing Access Group from here will stop auto-sync for the users in this group and will deactivate them at Swiftlane.\n' +
            'The users who are part of at least one other active group will remain active.\n' +
            'Do you want to continue?',
        customerGroups: 'Customer Groups',
        groups: 'Groups',
        syncGroupsMsg: {
            p1: 'All users from',
            p2: 'groups Selected below will be imported. Swiftlane will create groups with the same name.',
        },
        syncSelectedGroups: 'Sync Selected Groups',
        syncConfig: 'Sync Configurations',
        syncLogs: 'Sync Logs',
        addedGroupsToSyncSuccess: 'Groups successfully added to Synchronization',
        logsList: 'Logs List',
        action: 'Action',
    },

    eagleEye: {
        title: 'Eagle Eye Instance',
        connections: 'Eagle Eye Connections',
        userName: 'User Name',
        connectionDeleted: 'Connection is Deleted',
        viewMappings: 'View Cameras mapping',
        viewCameras: 'View Cameras',
        deleteConnectionConfirm: 'Are you sure you want to delete connection?',
        doorMapping: 'Door Mapping',
        editCameras: 'Edit Cameras',
        assignCameras: 'Assign Cameras',
        cameras: 'Cameras',
        connectedDoor: 'Connected Door',
        playLiveStream: 'Play Live Stream',
        authorizationTitle: 'New Eagle Eye Connection',
        authorizationDescription:
            'Please provide the two factor code that you received on your email [phone], so that the authentication can be completed.',
        authorizationUsername: 'Username',
        authorizationPassword: 'Password',
        authorizationFooterText: 'Swiftlane handles and transmits your Eagle Eye credentials securely and over an encrypted channel.',

        twoFactorCodeDescription:
            'Please provide the two factor code that you received on your email [phone], so that the authentication can be completed.',
        twoFactorCodeLabel: 'Two factor code',
        twoFactorCodeFooterText: 'Swiftlane handles and transmits your Eagle Eye credentials securely and over an encrypted channel.',

        connectionTitle: 'Eagle Eye',
        connectionDescription:
            'Eagle Eye is a provider of cloud based VMS systems. Use this integration to sync camera events and access control events between Swiftlane and Eagle Eye.',
        connectionText: 'No connections setup',
        connectionName: 'Connection Name',

        getConnectionsTitle: 'Eagle Eye Connections',
        getConnectionsItem1: 'EE-1 [Swiftlane provided name] [EE Account name]',
        getConnectionsItem2: 'EE-2 [Swiftlane provided name] [EE Account name]',

        loadingText: 'Connecting to Eagle Eye...',

        successMessageTitle: 'Login Successful!',
        successMessageDescription: 'Retreiving cameras...',

        errorMessageTitle: 'Looks like we hit an error!',
        errorMessageDescription: 'Credentials invalid',

        buttonBack: 'Back',
        buttonBackToIntegration: 'Back to connections',
        buttonCreateConnection: 'Create Connection',
        buttonAuthenticate: 'Authenticate',
        buttonConfirmCode: 'Confirm Code',
        deleteConnection: 'Delete Connection',
        addConnection: 'Add Connection',
    },

    vms: {
        cardTitle: 'Swiftlane Windows Agent',
        cardDesc: 'Windows agent for connecting to VMS and Access control systems',
        cardSubDesc:
            'Configure the Swiftlane Windows Agent to set up integration with VMS and access control systems. Click to learn more.',
        vms: 'VMS',
        agents: 'Agents',
        agent: 'Agent',
        downloadWindowsAgent: 'Download Windows Agent',
        downloadAgentDesc:
            'Please login to the Swiftlane Admin Dashboard from the Windows Server and download the Agent, after the Agent is downloaded press "Get Token" button.',
        download: 'Download',
        getToken: 'Get Token',
        enrollAgent: 'Enroll the Agent',
        getTokenDesc: 'Copy-paste this token to installed agent',
        registrationToken: 'Registration Token',
        next: 'Next',
        connectedAgents: 'Connected Agents',
        agentId: 'Agent Id',
        addAgent: 'Add agent',
        deleteAgentConfirm: 'Are you sure you want to delete the agent?',
        agentIsDeleted: 'Agent Is Deleted',
        connections: 'Connections',
        connectionDeleted: 'Connection is Deleted',
        serverAddress: 'Server Address',
        userName: 'User Name',
        type: 'Type',
        addConnection: 'Add Connection',
        deleteConnectionConfirm: 'Are you sure you want to delete connection?',
        viewMappings: 'View Cameras mapping',
        editConnection: 'Edit connection',
        connectionName: 'Connection name',
        salientHost: 'Salient Host',
        password: 'Password',
        connect: 'Connect',
        connectionAdded: 'Connection is added',
        connectionSaved: 'Connection is saved',
        connectionInfo: 'Connection Information',
        connectionType: 'Connection Type',
        salient: 'Salient',
        doorsMapping: 'Doors mapping',
        mappings: 'Mappings',
        connectedCameras: 'Connected Cameras',
        selectCamera: 'Select Camera',
        cameraIsAssigned: 'Camera is assigned',
        cameraIsRemoved: 'Camera is removed',
        tokenIsCopied: 'Token has been copied',
        agentDetails: 'Agent Details',
        version: 'Version',
        requiresUpdate: 'Requires Update',
        updates: 'Updates',
        automatedUpdates: 'Automated Updates',
        currentVersion: 'Current Version',
        availableVersion: 'Available Version',
        softwareDetails: 'Software Details',
        settingIsChanged: 'Setting Is Changed',
        releaseNotes: 'Release Notes',
        windowsAgentUpdateRequired: 'Windows Agent Update Required',
        updateNow: 'Update Now',
        softwareUpdated: 'software is Updated',
        noAvailableAgents: 'Sorry, but there is no Available Agents to download',
        reRegister: 'Re-Register',
        deleteAgent: 'Delete Agent',
        reRegisterAgent: 'Re-Register Agent',
        downloadAgent: 'Download Agent',
        reRegisterConfirm1:
            'Only one agent can be installed with the one Agent Id. If you Re-register, it’ll replace the previously installed agent instance. Re-register will still retain all connections of the agent. Alternatively, you can add multiple agents with different Agent Ids using',
        reRegisterConfirm2: ' button. Do you really want to Re-Register the existing agent?',
        reRegisterInstructions:
            'Please login into the Swiftlane admin dashboard from the windows server and download the agent, after agent is installed use token',
        logs: 'Logs',
        lastOnlineTime: 'Last online time',
    },
    milestone: {
        milestone: 'Milestone',
        cardTitle: 'Milestone integration',
        cardDesc: 'Integrate Milestone VMS to track events and videos in single UI',
        downloadAgentTitle: 'Download Milestone Access Control Plugin',
        downloadAgentDesc:
            'Please download the access control and deploy it to Milestone server. Get the token and use it for the registration process.',
        addInstance: 'Add Instance',
        instanceDetails: 'Instance Details',
        instances: 'Instances',
        instance: 'Instance',
        generalInfo: 'General Information',
        eventsConfiguration: 'Events Configuration',
    },
    appfolio: {
        name: 'Appfolio',
        sentEmailTo: 'Sent Email To',
        addConnection: 'Add Connection',
        propertyName: 'Property Name',
        connectionID: 'Connection ID',
        fileName: 'File Name',
        subject: 'Subject',
        connectionName: 'Connection Name',
        desc: 'Import resident data from AppFolio. You will need Property Name, Desc and Address to enroll. Once enrolled, Swiftlane will generate an email id. Please schedule Tenant Activity Report in AppFolio to be received on this email id daily once.',
    },
};
