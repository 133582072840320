export default {
    entrata: 'Entrata',
    cardDesc: 'Smarter Property Management',
    cardSubDesc: 'Import residence data from Entrata. You will need domain name, user ID and password to enroll',
    entrataPMS: 'Entrata - Property Management System',
    authenticateUsingEntrata: 'Please authenticate using your Entrata credentials to get list of properties you can manage at Entrata',
    entrataLogin: 'Entrata - Login',
    entrataDomainName: 'Entrata Domain Name',
    domainName: 'Domain Name',
    password: 'Password',
    getProperties: 'Get Properties',
    skip: 'Skip',
    entrataProperties: 'Entrata - Properties',
    propertyId: 'Property ID',
    marketingName: 'Marketing Name',
    type: 'Type',
    select: 'Select',
    selectSitesForAutosync: 'Please select sites you want to configure for Auto-sync with Swiftlane',
    selected: 'Selected',
    connections: 'Connections',
    addProperties: 'Add Properties',
    reauthorize: 'Re-authorize',
    authorize: 'Authorize',
};
