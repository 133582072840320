import React, { FC, lazy, Suspense } from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import FetchingData from 'components/common/Spinner/FetchingData';
import routes from 'config/routes';
import { Roles } from 'config/general';
import RedirectTo from 'pages/Integrations/Locks/components/Redirect';

const UserFeed = lazy(() => import('pages/User/UserFeed'));
const UserProfile = lazy(() => import('pages/User/Profile'));
const UserDoorAccess = lazy(() => import('pages/User/DoorAccess'));
const UserCreds = lazy(() => import('pages/User/Credentials'));
const VisitorPass = lazy(() => import('pages/User/VisitorPass'));
const Subscriptions = lazy(() => import('pages/User/Subscriptions'));
const ActivityFeed = lazy(() => import('pages/ActivityFeed'));
const Users = lazy(() => import('pages/Users'));
const UserGroups = lazy(() => import('pages/Groups'));
const SitesAndAccessPoints = lazy(() => import('pages/SitesAndAccessPoints'));
const Schedules = lazy(() => import('pages/Schedules'));
const HealthCheck = lazy(() => import('pages/HealthCheck'));
const VisitorSettings = lazy(() => import('pages/Auth/VisitorSettings'));
const AuditLogs = lazy(() => import('pages/AuditLogs'));
const LoginPage = lazy(() => import('pages/Auth/Login'));
const EnterWorkspacePage = lazy(() => import('pages/Auth/EnterWorkspace'));
const JoinWorkspace = lazy(() => import('pages/Auth/JoinWorkspace'));
const EmailVerification = lazy(() => import('pages/Auth/EmailVerification'));
const ConfirmEmail = lazy(() => import('pages/Auth/ConfirmEmail'));
const NewPassword = lazy(() => import('pages/Auth/NewPassword'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const Settings = lazy(() => import('pages/Settings'));
const Integrations = lazy(() => import('pages/Integrations'));
const Reports = lazy(() => import('pages/Reports'));
const Hardware = lazy(() => import('pages/Hardware'));
const VisitorAccess = lazy(() => import('pages/IntercomSettings'));
const VisitorPins = lazy(() => import('pages/VisitorPins'));
const Announcements = lazy(() => import('pages/Announcements'));
const PackageDelivery = lazy(() => import('pages/PackageDelivery'));

// const TailgatingDetection = lazy(() => import('legacy/components/tailgating-detection'));
// const VisitorAccess2 = lazy(() => import('legacy/components/visitorAccess'));
// const VisitorHealthCheckQuestions = lazy(() => import('legacy/components/visitorAccess/questions'));

const CommandCenter = lazy(() => import('pages/CommandCenter'));

const RentGpt = lazy(() => import('pages/RentGpt'));
const Billing = lazy(() => import('pages/Billing'));
const HelpSupport = lazy(() => import('pages/HelpSupport'));

const Routes: FC = () => {
    return (
        <Suspense fallback={<FetchingData loading />}>
            <Switch>
                <PrivateRoute
                    allowedRoles={[Roles.admin, Roles.user]}
                    exact
                    path={[routes.home, routes.activityFeed.root]}
                    component={ActivityFeed}
                />
                <PrivateRoute path={routes.sites.root} component={SitesAndAccessPoints} />
                <PrivateRoute path={routes.settings.root} component={Settings} />
                <PrivateRoute path={routes.users.root} component={Users} />
                <PrivateRoute path={routes.intercomSettings.root} component={VisitorAccess} />
                <PrivateRoute path={routes.visitorPins.root} component={VisitorPins} />
                <PrivateRoute path={routes.schedules.root} component={Schedules} />
                <PrivateRoute path={routes.integrations.root} component={Integrations} />
                <PrivateRoute exact path={routes.auditLogs.root} component={AuditLogs} />
                <PrivateRoute path={routes.hardware.root} component={Hardware} />

                <PrivateRoute path={routes.user.feed} component={UserFeed} allowedRoles={[Roles.user]} />
                <PrivateRoute path={routes.user.profile.root} component={UserProfile} allowedRoles={[Roles.user]} />
                <PrivateRoute path={routes.user.access} component={UserDoorAccess} allowedRoles={[Roles.user]} exact />
                <PrivateRoute path={routes.user.credentials} component={UserCreds} allowedRoles={[Roles.user]} exact />
                <PrivateRoute path={routes.user.passes} component={VisitorPass} allowedRoles={[Roles.user]} />
                <Route path={routes.user.subscriptions} component={Subscriptions} />
                {/* Provide Access to app when user is navigated from mobile app */}

                <PrivateRoute path={routes.userGroups.root} component={UserGroups} />
                <PrivateRoute path={routes.reports.root} component={Reports} />
                <PrivateRoute path={routes.auth.visitorSettings} component={VisitorSettings} />
                <PrivateRoute path={routes.healthCheck.root} component={HealthCheck} />
                <Route path={routes.auth.enterWorkspace} component={EnterWorkspacePage} />
                <Route path={routes.auth.login} component={LoginPage} />
                <Route path={routes.auth.confirmEmail} component={ConfirmEmail} />
                <Route path={routes.auth.forgotPassword} component={NewPassword} />
                <Route path={routes.auth.joinWorkspace} component={JoinWorkspace} />
                <Route path={routes.auth.emailVerification} component={EmailVerification} />
                {/* <Route path={routes.healthCheck.test} component={VisitorHealthCheckQuestions} /> */}
                {/* <Route path={routes.intercomSettings.access} component={VisitorAccess2} /> */}
                {/* <PrivateRoute exact path='/tailgating-detection' component={TailgatingDetection} /> */}
                <PrivateRoute path={routes.augustRedirect.root} component={RedirectTo} />
                <PrivateRoute path={routes.announcements.root} allowedRoles={[Roles.admin, Roles.user]} component={Announcements} />

                <PrivateRoute path={routes.commandCenter.root} component={CommandCenter} />

                <PrivateRoute path={routes.rentGpt.root} component={RentGpt} />
                <PrivateRoute path={routes.billing.root} component={Billing} />
                <PrivateRoute path={routes.helpSupport.root} component={HelpSupport} />
                <PrivateRoute path={routes.packageDelivery.root} component={PackageDelivery} />
                <Route component={ErrorPage} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
