import { APIMethods, apiProxy, TResponse, Message } from 'utils/api/apiRequestProxy';
import { getFcmToken } from '../firebase';

export type TReqRegisterDevicePushMessaging = {
    token: string;
    device_type: string;
    app_type: string;
    common_user_id?: string;
};

export async function requestRegisterDevicePushMessaging(payload: TReqRegisterDevicePushMessaging): Promise<TResponse<Message>> {
    return apiProxy<Message>({
        url: '/register/user_device/',
        method: APIMethods.POST,
        data: payload,
        showToastErrMsg: false,
        showToastSuccessMsg: false,
    });
}

export const requestGetFcmToken = async () => {
    // register push notification and get token, we do it here as we need register when user re-login as we unregister on log-out
    let registration;
    let token = null;

    if ('serviceWorker' in navigator) {
        registration = await navigator.serviceWorker.getRegistration();
    }
    // isServiceWorkerAvailable - is needed to detect that service worker registration is done, for case when PWA updated changes
    if (registration) {
        token = await getFcmToken(registration);
    }
    return token;
};
