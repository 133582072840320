export default {
    title: 'Accounts',
    createWorkspace: 'Create Account',
    workspacesList: 'Account List',
    workspaceName: 'Account Name',
    customerType: 'Customer Type',
    primaryColor: 'Primary Color',
    searchByName: 'Search by name',
    areYouSureDeactivate: 'Are you sure you want to Deactivate the Account?',
    addNew: 'Add new Account',
    edit: 'Edit Account',
    info: 'Account Information',
    displayName: 'Display Name',
    displayNameExample: 'Name, e.g.: lorem-ipsum',
    addWorkspace: 'Add Account',
    directCustomer: 'Direct Customer',
    integrator: 'Integrator',
    demo: 'Demo',
    selectCustomerType: 'Select customer type',
    admins: 'Account admins',
    addAdmin: 'Add Admin',
    sendInvite: 'Send invite',
    emailInvitation: 'Email Invitation',
    settings: 'Settings',
    enableFaceRecognition: 'Enable Face Recognition',
    enableAuthProviders: 'Enable Auth Providers',
    numKeyCardReaders: 'Number of Key Card Readers',
    numKeyCards: 'Number of Key Cards',
    numUsers: 'Number of Users',
    numAccessPoints: 'Number of Access Points',
    numTenants: 'Number of Tenants',
    numFrontDesc: 'Number of Front descs',
    enableWindowsAgents: 'Enable Windows Agents',
    enableEagleEyeIntegration: 'Enable Eagle Eye Integration',
    propertyManagementSystem: 'Property Management System',
    enableRealPage: 'Enable RealPage Integration',
    enableEntrata: 'Enable Entrata Integration',
    addSite: 'Add Site',
    siteId: 'Site ID',
    pmcId: 'PMC ID',
    addSiteConnection: 'Add Site Connection',
    realPageConnections: 'RealPage Connections',
    integrations: 'Integrations',
    getAdminAccess: 'Get Read Access',
    accessReason: 'Access Reason',
    getAccess: 'Get Access',
    getWriteAccess: 'Get Write Access',
    stidSettings: 'STID Settings',
    enableStidReaders: 'Enable STid Readers',
    stidClientId: 'STID Client ID',
    clientKey: 'Client Secret Key',
    numOfVirtualCards: 'Number of Virtual Key Cards',
    readerConfigProfile: 'Reader Configuration Profile',
    stidSiteName: 'STID Site Name',
    enableYardiIntegration: 'Enable Yardi Integration',
    yardiConnections: 'Yardi Connections',
    addYardiConnection: 'Add Yardi Connection',
    propertyId: 'Property ID',
    addRealPageConnection: 'Add RealPage Connection',
    searchByAddress: 'Search by address',
    enableHealthCheck: 'Enable Health Check',
    enableHandsfreeUnlock: 'Enable Hands-free Unlock',
    readerPreconfigured: 'Reader Pre-configured',
    handsfreeHelpTxt: 'This toggle will enable Nearby mode for STid readers.',
    preconfiguredHelpTxt:
        'The readers are already configured by STid as per config file shared by Swiftlane. Hence, no need to manually configure the readers.',
    searchById: 'Search by ID',
    addVCards: 'Add Virtual Cards',
    enterVCardsToAdd: 'Enter virtual cards amount to add',
    totalPurchasedCards: 'Total purchased cards',
    totalAssignedCards: 'Total assigned cards',
    remainingCards: 'Remaining cards',
    numDeletedCards: 'Number of Deleted cards',
    stidPortal: 'STid Reseller Portal',
    smartLocks: 'Smart Locks',
    enableAugustIntegration: 'Enable August/Yale locks Integration',
    maxLocksAllowed: 'Max number of Smart Locks is allowed to add in Swiftlane',
    numAddedSmartLocks: 'Number of Smart locks added to Swiftlane',
    numRemainingSmartLocks: 'Number of Smart locks remaining to be added',
    endUserBilling: 'End User Billing',
    enableUserBilling: 'Enable End User Billing',
    isInIllinois: 'Is the customer building in IL?',
    isInIllinoisHint:
        'The Face Recognition option is not available in state of Illinois. If customer site is in Illinois, please select Yes.',
    enableFaceRecognitionHelpTxt:
        'The Face Recognition option is not available in state of Illinois. Please make sure either customer Site is not in Illinois or you have got exception approved to enable Face Recognition access for this Account. Do you want to enable?',
    hardware: 'Hardware',
    doorControllers: 'Door Controllers',
    swiftReaderX: 'Swift Readers X',
    iosDevices: 'IOS Devices',
    links: 'Links',
    accessControlSystems: 'Access Control Systems',
    enableBrivoIntegration: 'Enable Brivo Integration',
    addMeAsAdmin: 'Add me as Admin',
    deactivationConfirm: 'Are you sure you want to deactivate the Account?',
    acu: {
        offlineAccess: 'Offline Access',
        enableOfflineAccess: 'Enable Offline Access',
        deviceMappings: 'ACU - device mappings',
        deviceId: 'Device ID',
        deviceType: 'Device Type',
        mappedAcu: 'Mapped ACU',
        removeMappedAcu: 'Remove mapped ACU',
        removeMappingConfirmation: 'Are you sure you want to remove the mapping?',
        addNewMapping: 'Add new mapping',
    },
    salesForceLink: 'Salesforce Opportunity URL',
    projectTrackerLink: 'Project Tracker URL',
    additionalInfo: 'Additional Info',
    qbAccountLink: 'Quickbooks Account Link',
    qbCustomerName: 'Quickbooks Customer Name',
    qbCustomer: 'Quickbooks Customer',
    autoPullInvoices: 'Automatically Pull All Invoices for this Customer from Quickbook',
    billing: 'Billing',
    QBCustomerLink: 'QB Customer Link',
    stidReusableCredsLabel: 'Switch to Reusable STID Credentials',
};
