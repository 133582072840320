import { useEffect } from 'react';
import { IFCMState } from 'store/fcm/types';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { useMutation, useQuery } from 'react-query';
import { requestRegisterDevicePushMessaging, requestGetFcmToken } from 'services/pushMessaging';
import { IRecentAuth } from 'types/auth';
import { uniq } from 'lodash';

const useRegisterPushMessaging = () => {
    const { isLoggedIn, recentAuths } = useSelector(({ authentication }: ICustomMap) => authentication || {});
    const { token: fcmToken, isServiceWorkerAvailable }: IFCMState = useSelector(({ firebase }: ICustomMap) => firebase);

    const { mutate: registerPushMessages } = useMutation(requestRegisterDevicePushMessaging);

    useQuery({
        queryKey: 'fcm-token',
        queryFn: () => requestGetFcmToken(),
        enabled: isServiceWorkerAvailable && !fcmToken && isLoggedIn,
    });

    useEffect(() => {
        if (isLoggedIn && fcmToken) {
            registerPushMessages({
                token: fcmToken,
                device_type: 'web',
                app_type: 'web',
            });
        }
    }, [fcmToken, isLoggedIn, registerPushMessages]);

    useEffect(() => {
        if (recentAuths && isLoggedIn && fcmToken) {
            const auths = (Object.values(recentAuths) || []) as IRecentAuth[];
            const commonUserIds = uniq(auths.map(({ commonUserId }) => commonUserId) || []);
            commonUserIds?.forEach(commonUserId => {
                registerPushMessages({
                    token: fcmToken,
                    device_type: 'web',
                    app_type: 'web',
                    common_user_id: commonUserId,
                });
            });
        }
    }, [fcmToken, isLoggedIn, recentAuths, registerPushMessages]);
};

export default useRegisterPushMessaging;
