// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

export const selectExtraOptionFilter: FilterFunc<DefaultOptionType> = (inputValue, option) =>
    option?.props?.children?.props?.label?.toLowerCase().includes(inputValue.toLowerCase());

export const selectOptionFilter: FilterFunc<DefaultOptionType> = (inputValue, option) =>
    (option?.props?.children).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;

export const selectExtraOptionFullFilter: FilterFunc<DefaultOptionType> = (inputValue, option) =>
    `${option?.value} ${option?.props?.children?.props?.subLabel} ${option?.props?.children?.props?.label}`
        ?.toLowerCase()
        .includes(inputValue.toLowerCase());
