import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import TwilioRom from 'components/common/TwilioRom';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ICustomMap } from 'types/ICustomMap';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ITokens } from 'types/auth';
import { cacheUserTokens } from 'utils/auth';
import { setUserTokensAction } from 'store/authentication/actions';
import { useQueryClient } from 'react-query';
import { authAccountQueryKey } from 'pages/CommandCenter/Accounts/config';
import routes from 'config/routes';
import useWindowVisibilityChange from 'hooks/useWindowVisibilituChange';
import { getRingtoneUrl } from 'utils/ringtone';
import Sound from 'components/common/Sound';
import { EMessageTypes, IMessage } from './types';

import styles from './styles.module.scss';

const PushNotifications: FC = () => {
    const [message, setMessage] = useState<IMessage>();
    const [animate, setAnimate] = useState(false);
    const [centerRoom, setCenterRoom] = useState(false);
    const [authTokens, setAuthTokens] = useState<ITokens | null>(null);
    const { recentAuths, user } = useSelector(({ authentication }: ICustomMap) => authentication);
    const { id_str: accountId } = useSelector(({ workspace }: ICustomMap) => workspace);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const history = useHistory();

    const location = useLocation();
    const { room_name, room_sid, access_point_id, access_point_name, visitor_photo_url, workspace_name, site_name }: ICustomMap = useMemo(
        () => queryString.parse(location.search) || {},
        [location.search],
    );

    useWindowVisibilityChange();

    useEffect(() => {
        const onMessage = ({ data: payload }: ICustomMap) => {
            // debugger;
            if (payload?.data?.type === EMessageTypes.START_CALL) {
                const auth = recentAuths[payload.data.company_id];
                if (auth) {
                    setAuthTokens({ access_token: auth.access_token, refresh_token: auth.refresh_token });
                    setMessage(payload.data);
                    setTimeout(() => {
                        setAnimate(true);
                    }, 200);
                }
            } else if (payload?.data?.type === EMessageTypes.END_CALL) {
                setMessage(undefined);
                setAnimate(false);
                setCenterRoom(false);
                setAuthTokens(null);
            }
        };
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', onMessage);
        }
        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener('message', onMessage);
            }
        };
    }, [recentAuths]);

    useEffect(() => {
        if (room_sid && room_name) {
            setMessage({
                room_name,
                room_sid,
                access_point_id,
                access_point_name,
                visitor_photo_url,
                workspace_name,
                site_name,
            });
            setAnimate(true);
            window?.history?.pushState({ path: window.location.origin }, '', window.location.origin);
        }
    }, [access_point_id, access_point_name, room_name, room_sid, site_name, visitor_photo_url, workspace_name]);

    const onEndCallPress = useCallback(() => {
        setMessage(undefined);
        setAnimate(false);
        setCenterRoom(false);
    }, []);

    const onCallAnswer = useCallback(() => {
        setCenterRoom(true);
        if (authTokens && accountId !== message?.company_id) {
            const tokens: ITokens = {
                access_token: authTokens.access_token,
                refresh_token: authTokens.refresh_token,
            };
            cacheUserTokens(tokens);
            dispatch(setUserTokensAction(tokens));
            queryClient.invalidateQueries(authAccountQueryKey); // Note: it is very important to refresh account data, base on account id we can get user data, and critical data
            history.push(routes.home);
        }
    }, [accountId, authTokens, dispatch, history, message, queryClient]);

    const ringAudio = getRingtoneUrl(user?.ringtone);

    const canRingAudio = useMemo(() => {
        // @ts-ignore
        return ringAudio && message && typeof window?.isTabVisible !== 'undefined' && window?.isTabVisible;
    }, [ringAudio, message]);

    return (
        <div className={cn(styles.push, { [styles.visible]: animate, [styles.center]: centerRoom })}>
            {message && (
                <div className={styles.room}>
                    <TwilioRom
                        authTokens={authTokens}
                        roomName={message.room_name}
                        photo={message.visitor_photo_url}
                        siteName={message.site_name}
                        accessPointId={message.access_point_id}
                        accessPointName={message.access_point_name}
                        roomId={message.room_sid}
                        workspaceName={message.workspace_name || ''}
                        onEndCallPress={onEndCallPress}
                        onCallAnswer={onCallAnswer}
                    />
                </div>
            )}
            {canRingAudio && <Sound audio={ringAudio} />}
        </div>
    );
};

export default memo(PushNotifications);
