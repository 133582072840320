import React, { FC, memo } from 'react';
import doorIcon from 'assets/svg/door.svg';

import MicIcon from 'components/common/Icons/Mic';
import VideoIcon from 'components/common/Icons/Video';
import PhoneMissedIcon from 'components/common/Icons/PhoneMissed';
import cn from 'classnames';
import Button from 'components/antd/Button';
import { textsPushNotifications } from 'texts/index';
import useRoom from 'components/common/TwilioRom/useRoom';
import FilledUser from 'components/common/Icons/FilledUser';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import CountDown from 'components/common/CountDown';
import { ITokens } from 'types/auth';
import PhoneCallIcon from 'components/common/Icons/PhoneCall';
import rootStyles from './styles.module.scss';
import Participant from './Participant';

interface IProps {
    authTokens?: ITokens | null;
    roomName: string;
    roomId: string;
    photo?: string;
    accessPointName?: string;
    accessPointId: string;
    workspaceName?: string;
    siteName?: string;
    accessToken?: string; // for live video support
    roomDescription?: string;
    styles?: {
        root?: string;
        media?: string;
        userPlaceholder?: string;
        info?: string;
    };
    onEndCallPress(): void;
    onCallAnswer?(): void;
}

const TwilioRom: FC<IProps> = ({
    authTokens,
    roomName,
    photo,
    workspaceName,
    siteName,
    roomId,
    accessPointName,
    accessPointId,
    onEndCallPress,
    onCallAnswer,
    accessToken,
    roomDescription,
    styles,
}: IProps) => {
    const {
        room,
        token,
        participants,
        isVideoOnMute,
        isAudioOnMute,
        isUnlocking,
        isAudioAnswering,
        isDoorUnlocked,
        showCountDown,
        toggleMuteVideo,
        toggleMuteAudio,
        handleUnlockDoor,
        handleAnswerCall,
        handleEndCall,
    } = useRoom({
        authTokens,
        accessToken,
        roomName,
        roomId,
        accessPointId,
        onEndCallPress,
        onCallAnswer,
    });

    const { name: defaultWorkspaceName } = useSelector(({ workspace }: ICustomMap) => workspace);

    return (
        <div className={cn(rootStyles.root, { [styles?.root || '']: styles?.root })}>
            <div className={cn(rootStyles.media, { [styles?.media || '']: styles?.media })}>
                {photo && !participants.length && <img src={photo} alt='' />}
                {!photo && !participants.length && (
                    <div className={cn(rootStyles.noPhoto, { [styles?.userPlaceholder || '']: styles?.userPlaceholder })}>
                        <FilledUser className={rootStyles.userIcon} />
                    </div>
                )}
                {(participants.length > 0 || room?.localParticipant) && (
                    <>
                        <Participant participant={participants?.[0]} />
                        <div className={cn(rootStyles.localMedia, { [rootStyles.localVideoMute]: isVideoOnMute })}>
                            <Participant participant={room?.localParticipant} />
                        </div>
                    </>
                )}
            </div>
            <div className={cn(rootStyles.info, { [styles?.info || '']: styles?.info })}>
                <div className={rootStyles.box}>
                    <div>
                        <img src={doorIcon} alt='' />
                    </div>
                    <h2 className={rootStyles.title}>{workspaceName || defaultWorkspaceName}</h2>
                    {siteName && <h3 className={rootStyles.siteName}>{siteName}</h3>}
                    <p>{roomDescription || `${textsPushNotifications.message1} ${accessPointName} ${textsPushNotifications.message2}.`}</p>
                    {isDoorUnlocked && <div className={rootStyles.unlockedMsg}>Door is Unlocked</div>}
                    {token ? (
                        <div className={rootStyles.actions}>
                            <div>
                                <Button type='primary' loading={isAudioAnswering} onClick={toggleMuteAudio}>
                                    {!isAudioAnswering && <MicIcon />}
                                    {isAudioOnMute && <em className={rootStyles.redLine} />}
                                </Button>
                                <span className={rootStyles.btnTxt}>
                                    {textsPushNotifications.audio}
                                    {isAudioOnMute && ` ${textsPushNotifications.off}`}
                                    {!isAudioOnMute && ` ${textsPushNotifications.on}`}
                                </span>
                            </div>
                            <div>
                                <Button type='primary' onClick={toggleMuteVideo}>
                                    <VideoIcon />
                                    {isVideoOnMute && <em className={rootStyles.redLine} />}
                                </Button>
                                <span className={rootStyles.btnTxt}>
                                    {textsPushNotifications.video}
                                    {isVideoOnMute && ` ${textsPushNotifications.off}`}
                                    {!isVideoOnMute && ` ${textsPushNotifications.on}`}
                                </span>
                            </div>
                            <div>
                                <Button danger onClick={handleEndCall} className={rootStyles.missBtn}>
                                    <PhoneMissedIcon />
                                </Button>
                                <span className={cn(rootStyles.btnTxt, rootStyles.red)}>{textsPushNotifications.endCall}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={rootStyles.answerBtns}>
                            <Button
                                className={rootStyles.answerBtn}
                                type='primary'
                                size='large'
                                green
                                ghost
                                loading={isAudioAnswering}
                                onClick={handleAnswerCall}
                                icon={<PhoneCallIcon />}
                            >
                                {textsPushNotifications.answerCall}
                            </Button>
                            <Button
                                className={rootStyles.ignoreBtn}
                                danger
                                onClick={isAudioAnswering ? undefined : handleEndCall}
                                size='large'
                                icon={<PhoneMissedIcon />}
                            >
                                {textsPushNotifications.ignoreCall}
                            </Button>
                        </div>
                    )}
                    {isDoorUnlocked && showCountDown && (
                        <div className={rootStyles.autoEndCallBox}>
                            <strong>
                                Your call will end in <CountDown seconds={30} onDone={handleEndCall} /> seconds
                            </strong>
                        </div>
                    )}
                </div>
                <div className={rootStyles.unlockBtn}>
                    <Button type='primary' onClick={handleUnlockDoor} loading={isUnlocking}>
                        {textsPushNotifications.unlockDoor}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default memo(TwilioRom);
