import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get } from 'lodash';
import { IMetaData } from 'types/IRespMetaData';
import { APIMethods, IAPIRequest, IOptions, IRequestApiConfig, IUseRequestApiRes } from 'hooks/useRequestApi/types';
import { getAPI, prepareRoute } from 'hooks/useRequestApi/utils';
import { getErrorMsg, getSuccessMsg } from 'utils/getErrorMsg';
import { ICustomMap } from 'types/ICustomMap';
import { parseErrors } from 'utils/parseErrors';
import { TError } from 'types/errors';
import { toast } from 'react-toastify';

const defaultOptions: IOptions = { popupErrMsg: true, popupSuccessMsg: true };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRequestApi = <TRequest extends IAPIRequest = IAPIRequest, TData = any>(
    { uri, method = APIMethods.GET, respKey, config }: IRequestApiConfig,
    options: IOptions = defaultOptions,
): [(payload?: TRequest) => ICustomMap, IUseRequestApiRes<TData>] => {
    const isSubscribed = useRef<boolean>(false);
    const extraOptions = useRef<IOptions>({ ...defaultOptions, ...options }); // avoiding extra updates/loops for options conf object as it should be not dynamic
    const [data, setData] = useState<TData | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [metaData, setMetaData] = useState<IMetaData>({});
    const [errors, setErrors] = useState<ICustomMap | string[] | null>(null);

    useEffect(() => {
        isSubscribed.current = true;
        return () => {
            isSubscribed.current = false;
        };
    }, []);

    const makeCall = useCallback(
        async (payload?: TRequest) => {
            try {
                if (isSubscribed.current) {
                    setErrorMsg(null);
                    setErrors(null);
                    setLoading(true);
                }
                const requestAPI = getAPI(method);
                const route = prepareRoute(uri, payload?.uriVariables, payload?.query);

                const headers = payload?.headers ? { headers: payload?.headers } : {};
                const args =
                    method === APIMethods.GET || method === APIMethods.DELETE
                        ? [{ ...config, ...headers }]
                        : [payload?.body, { ...config, ...headers }];
                const resp: ICustomMap = await requestAPI(route, ...args);

                if (isSubscribed.current) setLoading(false);

                if (resp?.metadata) {
                    const { errors: errorsData, ...rest } = resp.metadata;
                    if (isSubscribed.current) setMetaData(rest);
                    if (isSubscribed.current) setErrors(errorsData);
                }
                if (resp?.errors) {
                    if (isSubscribed.current) setErrors(resp.errors);
                }
                if (isSubscribed.current) {
                    const respData = respKey ? get(resp, respKey || '') : resp;
                    setData(respData);
                    const successMsg = getSuccessMsg(respData);
                    if (extraOptions.current.popupSuccessMsg && successMsg) toast.success(successMsg);
                }
                return resp;
            } catch (err: TError) {
                if (isSubscribed.current) setLoading(false);
                if (isSubscribed.current) {
                    const errMsg = getErrorMsg(err);
                    setErrorMsg(errMsg);
                    if (extraOptions.current.popupErrMsg && err.config.method.toLowerCase() !== 'get') toast.error(errMsg);
                }
                if (err.response && err.response.data && err.response.status === 422) {
                    if (isSubscribed.current) setErrors(parseErrors(err.response.data[0]));
                }
                throw err;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [method, respKey, uri],
    );

    return useMemo(
        () => [makeCall, { data, isLoading, errorMsg, metaData, errors }],
        [data, errorMsg, errors, isLoading, makeCall, metaData],
    );
};

export default useRequestApi;
