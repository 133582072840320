export enum EMessageTypes {
    START_CALL = 'START_CALL',
    END_CALL = 'END_CALL',
}

export interface IMessage {
    access_point_id: string;
    access_point_name: string;
    company_id?: string;
    room_name: string;
    room_sid: string;
    site_id?: string;
    site_name: string;
    type?: EMessageTypes;
    user_id?: string;
    visitor_photo_url: string;
    workspace_name: string;
}
