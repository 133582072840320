export const requestMediaPermissions = async () => {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
};

export const checkMediaPermissions = async () => {
    const permissions = await Promise.all([
        navigator.permissions.query({ name: 'camera' as PermissionName }),
        navigator.permissions.query({ name: 'microphone' as PermissionName }),
    ]);

    const hasCameraPermission = permissions[0].state === 'granted';
    const hasMicrophonePermission = permissions[1].state === 'granted';

    if (!hasCameraPermission || !hasMicrophonePermission) {
        await requestMediaPermissions();
    }
};
