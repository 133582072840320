import { AnswerCall, ReqAnswerCall, ReqRemoteUnlock } from 'pages/IntercomSettings/FrontDescIntercom/types';
import { APIMethods, apiProxy, TResponse } from 'utils/api/apiRequestProxy';

export async function requestAnswerCall({
    platform,
    access_point_id,
    room_name,
    room_sid,
    authToken,
}: ReqAnswerCall): Promise<TResponse<AnswerCall>> {
    // As we have auto login to multiple accounts we need to pass the token to the request from proper account
    const headers = authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {};
    return apiProxy<AnswerCall>({
        method: APIMethods.POST,
        url: '/intercom/answer_call/',
        params: { platform },
        data: {
            access_point_id,
            room_name,
            room_sid,
        },
        ...headers,
    });
}

export async function requestRemoteUnlock({
    access_point_id,
    room_name,
    room_sid,
    authToken,
}: ReqRemoteUnlock): Promise<TResponse<AnswerCall>> {
    // As we have auto login to multiple accounts we need to pass the token to the request from proper account
    const headers = authToken ? { headers: { Authorization: `Bearer ${authToken}` } } : {};
    return apiProxy<AnswerCall>({
        method: APIMethods.POST,
        url: '/intercom/remote_unlock/',
        data: {
            access_point_id,
            room_name,
            room_sid,
        },
        ...headers,
    });
}
