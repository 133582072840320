import { ICustomMap } from 'types/ICustomMap';
import { isProd } from 'utils/isProd';

export const RentGPTBotID = isProd() ? 'd98be95d7fd85e646f50f9cf30978bdf' : '638f36113ae133e8bf6a880a46fafb26';
export const RentGPTBottUrl = isProd() ? 'https://rentgpt-tpaee2xktq-ue.a.run.app' : 'https://rentgpt-j4vz52w6ia-ue.a.run.app';

export const defaultSupportGPTConfig = {
    botID: RentGPTBotID,
    position: 'right',
    widget: {
        offsetRight: 20,
        offsetLeft: 20,
        offsetBottom: 20,
        buttonSize: 60,
    },
    welcomeMessage: {
        text: ' Hi. I’m SupportGPT - Ask me anything...',
        buttonSize: 24,
    },
    introMessage: 'Hi 👋 I’m SupportGPT. \n You can ask me anything and in any language...',
    theme: {
        primaryColor: '#330DF2',
        primaryTxtColor: '#fff',
    },
    footerLabel: 'SupportGPT Powered by',
    rreFetchActions: true,
};

export const bindSupportGptChatFiles = (config: ICustomMap) => {
    const url = RentGPTBottUrl;
    const hasScript = document.getElementById('rent-gpt-help-script');
    if (!hasScript) {
        const script = document.createElement('script');
        script.id = 'rent-gpt-help-script';
        script.src = `${url}/widget.js`;
        script.async = true;
        document.body.appendChild(script);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const styles = document.createElement('link');
        styles.rel = 'stylesheet';
        styles.href = `${url}/widget.css`;
        document.body.appendChild(styles);
        const o = '_ChatWidget';
        // @ts-ignore
        window.RGptWidget = o;
        // @ts-ignore
        window[o] =
            // @ts-ignore
            window[o] ||
            // @ts-ignore
            // eslint-disable-next-line func-names
            function () {
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params
                window[o].q = [arguments];
            };
        // @ts-ignore
        _ChatWidget('init', config);
    }
};
