import { IGetWorkspacesQuery } from 'pages/CommandCenter/Accounts/servicesConfig';
import { EYesNo, SortOrders } from 'config/general';
import { IPaging } from 'types/IPaging';

export enum AccountStatus {
    new = 'new',
    active = 'active',
    deactivated = 'deactivated',
}

export interface IWorkspace {
    customer_type: string;
    id_str: string;
    name: string;
    workspace_logo_path: string;
    workspace_name: string;
    workspace_photo_path: string;
    workspace_primary_color: string;
    cap_settings?: IWorkspaceSettings;
    activated_at?: string;
    status?: AccountStatus;
    payment_status?: string;
    project_details?: {
        sf_link?: string;
        project_tracker_link?: string;
        qb_account_link?: string;
        qb_account_id?: string;
    };
    last_template_update: string | null;
}

export interface IFormValues {
    name: string;
    workspaceName: string;
    customerType: string;
    color: string;
    enableFaceRecognition?: EYesNo;
    rentgpt?: boolean;
    sf_link?: string;
    project_tracker_link?: string;
    qb_account_link?: string;
    qb_account_id?: string;
}

export interface IAdminFormValues {
    firstName: string;
    lastName: string;
    email: string;
    sendInvite: boolean;
}

export interface IWorkspaceSettings {
    enable_face_recognition: boolean;
    enable_auth_providers: boolean;
    enable_end_user_billing: boolean;
    no_of_key_card_readers: number | null;
    no_of_key_cards: number | null;
    no_of_users: number | null;
    no_of_access_points: number | null;
    no_of_front_desks: number | null;
    no_of_tenants: number | null;
    enable_windows_agents?: boolean | null;
    enable_eagle_eye_integration?: boolean | null;
    enable_milestone?: boolean | null;
    enable_entrata_integration?: boolean | null;
    enable_real_page_integration?: boolean | null;
    enable_yardi_integration?: boolean | null;
    enable_health_check?: boolean | null;
    enable_smart_lock_integration?: boolean | null;
    enable_brivo_integration?: boolean | null;
    enable_rent_gpt: boolean;
    max_smart_locks_allowed?: number;
    enable_acu: boolean;
    max_qr_codes_allowed?: number;
    enable_package_scanning?: boolean;
    location_check?: boolean;
    readonly remaining_locks: number | null;
    readonly added_locks: number | null;
}

export interface ReqWorkspacesQuery extends IPaging {
    unified_name?: string;
    site_address?: string;
    company_id?: string;
    sf_link?: string;
    project_tracker_link?: string;
    qb_account_id?: string;
    account_status?: string;
    payment_status?: string;
    customer_type?: string;
    sort_by?: string;
    accounts_with_no_template?: boolean;
    sort_order?: SortOrders;
}

export type ReqUpdateAccount = {
    id: string;
    workspace_name: string;
    name: string;
    workspace_primary_color: string;
    customer_type: string;
    project_details: {
        sf_link?: string;
        project_tracker_link?: string;
        qb_account_link?: string;
        qb_account_id?: string;
    };
};

export enum ECustomerType {
    demo = 'demo',
    directCustomer = 'direct-customer',
    integrator = 'integrator',
}

export type AccountSummary = {
    total_face_unlocks_successful: number;
    total_intercom_calls: number;
    total_number_of_events: number;
    total_pin_unlocks_successful: number;
    total_unlock_failures: number;
    unique_users_accessed: number;
};

export type TFilters = IGetWorkspacesQuery;
export type THandleDeleteWorkspace = (id: string) => void;
export type THandleFormSubmit = (values: IFormValues) => void;
export type THandleAdminFormSubmit = (values: IAdminFormValues) => void;
