import React, { FC } from 'react';
import styles from 'components/App/styles.module.scss';
import { Link } from 'react-router-dom';
import routes from 'config/routes';
import mini_logo from 'assets/svg/mini-logo.svg';
import { textsCommon } from 'texts/index';

const EmptyHeader: FC = () => (
    <div className={styles.mainLogo}>
        <Link to={routes.home}>
            <img className={styles.logo} src={mini_logo} width={32} alt={textsCommon.swiftlane} />
            <span>{textsCommon.swiftlane}</span>
        </Link>
    </div>
);

export default EmptyHeader;
