import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { bindSupportGptChatFiles, defaultSupportGPTConfig } from './utils';

const useSupportGpt = () => {
    const { isLoggedIn, user } = useSelector(({ authentication }: ICustomMap) => authentication || {});
    const currentWorkspace = useSelector(({ workspace }: ICustomMap) => workspace);

    const initSupportGpt = useCallback(() => {
        if (!isLoggedIn || !user?.id_str || !currentWorkspace?.id_str) {
            return;
        }

        const config = {
            ...defaultSupportGPTConfig,
            extraInfo: {
                userEmail: user.email,
                userName: `${user.first_name} ${user.last_name}`,
                accountName: currentWorkspace.name,
                showInFirstMessage: true,
            },
        };

        setTimeout(() => {
            bindSupportGptChatFiles(config);
        }, 1000);
    }, [currentWorkspace, isLoggedIn, user]);

    const setSupportGptConfig = useCallback(() => {
        if (!isLoggedIn || !user?.id_str || !currentWorkspace?.id_str) {
            return;
        }

        const config = {
            ...defaultSupportGPTConfig,
            extraInfo: {
                userEmail: user.email,
                userName: user.name,
                accountName: currentWorkspace.name,
                showInFirstMessage: true,
            },
        };
        // @ts-ignore
        if (window?.RGWidgetOpts) {
            // @ts-ignore
            window?.RGWidgetOpts.updateConfigs?.(config);
        }
    }, [currentWorkspace, isLoggedIn, user]);

    return {
        initSupportGpt,
        setSupportGptConfig,
    };
};

export default useSupportGpt;
